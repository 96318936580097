import Swiper from 'swiper';
import {
    Navigation,
    Thumbs
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import 'select2';
import 'select2/dist/css/select2.css';

$(document).ready(function() {
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();

    $('.select-js').select2({
        minimumResultsForSearch: -1,
    });

    $('.language__current').click(function() {
        $(this).toggleClass('active');
        $('.language__list').toggleClass('active');

        return false;
    });

    $('.hamburger').click(function() {
        $('.header__bottom').addClass('active');

        return false;
    });

    $('.header__close').click(function() {
        $('.header__bottom').removeClass('active');

        return false;
    });

    $('.tabs__caption').on('click', 'li:not(.active)', function() {
        $(this)
            .addClass('active').siblings().removeClass('active')
            .closest('.tabs').find('.tabs__content').removeClass('active').eq($(this).index()).addClass('active');
    });

    $('.product__list-btn').click(function() {


        $(".product__form").slideToggle({
            duration: 600,
            start: function() {
                $(this).css('display', 'flex');
            }
        });

        return false;
    });
});